<template>
  <div>
    <v-row align="center" justify="center" no-gutters>
      <h2 class="text-center primary--text">ข้อมูลสมาชิก</h2>
    </v-row>
    <v-row align="center" justify="center" no-gutters>
      <v-card
        class="mt-2 pa-6 text-center"
        style="background-color: #0092d2"
        width="80%"
      >
        <h1 style="color: white; font-weight: 800">คุณ</h1>
        <span style="color: white">ระบบจะส่งรหัสเข้าตู้เซฟของสมาชิกไปให้</span>
        <v-row class="mt-2">
          <v-col cols="8">
            <v-text-field
              v-model="telNumber"
              solo
              background-color="white"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              style="
              background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
            "
              @click="login()"
              >ขอรหัส</v-btn
            >
          </v-col>
        </v-row>
        <span style="color: white">
          มีเพียงสมาชิกเก่าเท่านั้นที่สามารถเข้าสู่ระบบผ่านเบอร์มือถือได้
          หากเป็นสมาชิกใหม่โปรดสมัครผ่านช่องทางไลน์</span
        >
        <h3 style="color: white; font-weight: 800">หรือ</h3>
        <v-btn color="#02c756" style="color: white" @click="lindLogin()"
          >สมัครสมาชิกด้วยไลน์</v-btn
        >
      </v-card>
    </v-row>
  </div>
</template>
<script>
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
export default {
  data() {
    return {
      telNumber: "",
    };
  },
  methods: {
    async login() {
      const data = {
        socialId: "",
        telNumber: this.telNumber,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/login`,
        data
      );
      console.log("response", response);
      if (response.data.response_status === "SUCCESS") {
        localStorage.setItem("DDLotUser", encode(response.data.data));
        // this.$store.commit('SetModalLogin')
        // this.$EventBus.$emit('checkuser')
        this.$router.push({ path: "/" });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "ไม่มีข้อมูลผู้ใช้งาน โปรดติดต่อเจ้าหน้าที่",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    lindLogin() {
      window.open(
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655949122&redirect_uri=https://readylottery.com/member&state=12345abcde&scope=profile%20openid&nonce=09876xyz"
      );
    },
  },
};
</script>
